export const heroDataHome = {
  primary: true,
  classModifier: 'home',
  img: 'dashboard-demo',
  signupButton: true,
  demoLink: true,
}

export const heroDataInstagramAnalytics = {
  classModifier: 'instagramAnalytics',
  img: 'instagram-analytics',
  imgEn: 'instagram-analytics-en',
  signupButton: true,
}

export const heroDataInstagramCompetitors = {
  classModifier: 'igCompetitors',
  img: 'ig-competitors-page',
  signupButton: true,
}

export const heroDataAuditInstagram = {
  img: 'audit-instagram',
  classModifier: 'auditInstagram',
  signupButton: true,
}

export const heroDataVkAnalytics = {
  img: 'vk-analytics',
  classModifier: 'vkAnalytics',
  signupButton: true,
}

export const heroDataStatistikaAkkauntaTikTok = {
  img: 'statistika-akkaunta-tik-tok',
  classModifier: 'statistika-akkaunta-tik-tok',
  signupButton: true,
}

export const heroDataTelegramAnalytics = {
  img: 'statistika-telegram',
  classModifier: 'statistika-telegram',
  signupButton: true,
}

export const heroDataStatistikaOdnoklassniki = {
  img: 'statistika-odnoklassniki',
  classModifier: 'statistika-odnoklassniki',
  signupButton: true,
}

export const heroDataFBAnalytics = {
  img: 'fb-analytics',
  classModifier: 'fbAnalytics',
  signupButton: true,
}

export const heroDataInstagramStoriesStats = {
  img: 'instagram-stories-stats-page',
  classModifier: 'instagramStoriesStats',
  signupButton: true,
}

export const heroDataDownloadCommentsInstagram = {
  img: 'download-comments-instagram',
  classModifier: 'downloadCommentsInstagram',
  signupButton: true,
  accountSearch: false
}

export const heroDataDownloadCommentsInstagramExp = {
  img: 'download-comments-instagram',
  classModifier: 'downloadCommentsInstagram',
  signupButton: false,
  accountSearch: true
}

export const heroDataMessages = {
  img: 'messages',
  classModifier: 'messages',
  signupButton: true,
}

export const heroDataAbout = {
  classModifier: 'about',
}

export const heroDataContacts = {
  img: 'contacts',
  classModifier: 'contacts',
}

export const heroDataPartners = {
  img: 'partners',
  classModifier: 'partners',
  signupButton: true,
}

export const heroDataAccountStats = {
  img: 'account-stats',
  imgEn: 'account-stats-en',
  classModifier: 'accountStats',
  signupButton: true,
}

export const heroDataDirectInstagram = {
  img: 'instagram-direct-page',
  classModifier: 'directInstagram',
  signupButton: true,
}

export const heroDataAutoposting = {
  img: 'autoposting-page',
  classModifier: 'autoposting',
  linkToScroll: '#products',
  signupButton: true,
}

export const heroDataAutopostingVK = {
  img: 'autoposting-page',
  classModifier: 'autoposting-vk',
  linkToScroll: '#products',
  signupButton: true,
}

export const heroDataMonitoring = {
  img: 'monitoring-page',
  classModifier: 'monitoring',
  signupButton: true,
}

export const heroDataReports = {
  img: 'reports-page',
  classModifier: 'reports',
  signupButton: true,
}

export const heroDataBloggersCheck = {
  img: 'bloggers-check-page',
  classModifier: 'bloggersCheck',
  signupButton: true,
}

export const heroDataKpi = {
  img: 'kpi-page',
  classModifier: 'kpi',
  signupButton: true,
}

export const heroDataPricing = {
  classModifier: 'pricing',
}

export const heroDataServices = {
  classModifier: 'services',
  signupButton: true,
}
