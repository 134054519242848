import { useState } from 'react'
import { useRouter } from 'next/router'
import cns from 'classnames'
import PropTypes from 'prop-types'
import useFormatMessage from '@utils/useFormatMessage'

import $amplitude, { $amplitudeIdentifySetOnce } from '@utils/amplitude'

import ArrowRight from '@icons/arrow-right.svg'
import SearchThin from '@icons/search-thin.svg'

import styles from './analysisInput.module.scss'

export default function AnalysisInput({ pageId }) {
  const [input, setInput] = useState('')
  const [error, setError] = useState(null)
  const [focus, setFocus] = useState(false)

  const { locale } = useRouter()
  const t = useFormatMessage()

  const isHomePage = pageId === 'home'
  const isDownloadCommentsPage = pageId === 'download-comments-instagram'

  const handleFocusChange = () => {
    setFocus(true)
    if (isHomePage) {
      $amplitude('[Landing] Select Analyze Input Focus Click')
    }
  }

  const handleClick = async e => {
    e.preventDefault()
    const isAllowed = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{2,20}$/.test(input)

    if (!isAllowed) {
      setInput('')
      setError(t('analysis.input.error.validation'))

      $amplitude('[Landing] Search Account Analyze Button Click', {
        error_existence: true,
      })
      return
    }
    try {
      const res = await fetch(`/landing/searchAccounts?name=${input}`, {
        keepalive: true,
      })
      const data = await res.json()

      if (data.error) {
        setError(t('analysis.input.error.limit'))
        $amplitude('[Landing] Search Account Analyze Button Click', {
          error_existence: true,
        })
        return
      }
      if (data.data.length > 0) {
        setError(null)
        sessionStorage.setItem('accounts', JSON.stringify(data.data))
        $amplitude('[Landing] Search Account Analyze Button Click', {
          error_existence: false,
        })

        if (isDownloadCommentsPage) {
          $amplitudeIdentifySetOnce('Account Link', input)
          location.href = `/${locale}/analysis-result?search=${input}&flow=comments`
        } else {
          location.href = `/ru/analysis-result?search=${input}`
        }
        return
      }
      setError(t('analysis.input.error.empty'))
    } catch (error) {
      setError(t('analysis.input.error.empty'))
      $amplitude('[Landing] Search Account Analyze Button Click', {
        error_existence: true,
      })
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') handleClick(event)
  }

  return (
    <div className={styles.root}>
      <div
        className={cns(styles.container, {
          [styles.container_error]: error,
          [styles.container_clicked]: focus,
        })}
      >
        <div className={styles.search}>
          <i className={styles.icon}>
            <SearchThin />
          </i>
          <input
            type="text"
            placeholder={t('analysis.input.placeholder')}
            className={styles.input}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onFocus={handleFocusChange}
            onBlur={() => setFocus(false)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <button className={styles.button} onClick={handleClick}>
          <ArrowRight />
        </button>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  )
}

AnalysisInput.propTypes = {
  pageId: PropTypes.string,
}